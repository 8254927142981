import { useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import useScreenerStatus from "@ScreenerSetup/hooks/useScreenerStatus";
import { isEmpty } from "lodash-es";
import { parseCookies } from "nookies";
import useSWR from "swr";

import { API_URLS, SCREENING_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const screenersToCheck = [
  ScreenerVariant.AirCondition,
  ScreenerVariant.BoilerInstallationPostcodeAtTheEnd,
  ScreenerVariant.EVCharger,
  ScreenerVariant.HeatPump,
];

const finishQuoteImages = {
  [ScreenerVariant.Solar]: "https://boxt-bcms.imgix.net/R9X7gPOUTTWcHhxZzDO9",
  [ScreenerVariant.BoilerInstallation]: "https://boxt-bcms.imgix.net/jMBx2PJXThCX0u2fVpp0",
  [ScreenerVariant.HeatPump]: "https://boxt-bcms.imgix.net/q6TKvViCTSSMPzcfg9Ve",
  [ScreenerVariant.BoilerSubscription]: "https://boxt-bcms.imgix.net/A3THHdKnT8SIgdFtf0yc",
  [ScreenerVariant.AirCondition]: "https://boxt-bcms.imgix.net/o4UUWf4TyOZZsv9A9WaW",
};

const useQuotes = () => {
  const { t } = useTranslation("list/index");

  const finishQuoteTitles = {
    [ScreenerVariant.Solar]: t("title-hero.solar-title"),
    [ScreenerVariant.BoilerInstallation]: t("title-hero.boiler-title"),
    [ScreenerVariant.HeatPump]: t("title-hero.heat-pump-title"),
    [ScreenerVariant.BoilerSubscription]: t("title-hero.boiler-subscription-title"),
    [ScreenerVariant.AirCondition]: t("title-hero.air-condition-title"),
  };

  const router = useRouter();

  const [isFinishQuoteLoading, setIsFinishQuoteLoading] = useState(false);
  const [isNewQuoteBottomSheetOpen, setIsNewQuoteBottomSheetOpen] = useState(false);
  const [isFinishQuoteBottomSheetOpen, setIsFinishQuoteBottomSheetOpen] = useState(false);

  const screenerStates = useScreenerStatus([
    { variant: ScreenerVariant.BoilerInstallation },
    { variant: ScreenerVariant.BoilerSubscription },
    { variant: ScreenerVariant.Solar },
  ]);

  const { data, error, isValidating } = useSWR(
    { url: API_URLS.SCREENING_STATUS },
    ({ url }: { url: string }) =>
      Promise.all(screenersToCheck.map((variant) => fetcher(url, { screenerVariant: variant }))),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  );

  const allScreenersData = [
    ...[
      { ...screenerStates[ScreenerVariant.BoilerInstallation], screenerVariant: ScreenerVariant.BoilerInstallation },
      { ...screenerStates[ScreenerVariant.BoilerSubscription], screenerVariant: ScreenerVariant.BoilerSubscription },
    ],
    ...(data || []),
  ];

  const existingScreeners =
    (allScreenersData
      ?.map((screener) => {
        if (screener?.isScreenerComplete) {
          const cookies = parseCookies();
          const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${screener.screenerVariant}`];
          return {
            url: SCREENING_URLS[screener?.screenerVariant]?.complete.replace("[screeningId]", screeningId),
            variant: screener?.screenerVariant,
          };
        } else if (screener?.isScreenerStarted) {
          return {
            url: SCREENING_URLS[screener?.screenerVariant]?.start,
            variant: screener?.screenerVariant,
          };
        }
        return null;
      })
      .filter(Boolean) as { variant: ScreenerVariant; url: string }[]) || [];

  const isLoading = (!data && !error) || isValidating;

  const handleFinishQuoteClick = () => {
    setIsFinishQuoteLoading(true);
    if (Number(existingScreeners?.length) > 1) {
      setIsFinishQuoteBottomSheetOpen(true);
      setIsFinishQuoteLoading(false);
    } else if (existingScreeners?.length === 1) {
      router?.push({
        pathname: existingScreeners[0]?.url,
      });
    }
  };

  const hasExistingScreeners = !isEmpty(existingScreeners);

  const generatedTiles =
    existingScreeners?.map((screener, i) => {
      const { variant, url } = screener;
      return {
        item: {
          image: finishQuoteImages[variant],
          key: i,
          link: url,
          meta: { id: i },
          slug: `finish-quote-${variant}`,
          title: finishQuoteTitles[variant],
        },
      };
    }) || [];

  const newQuoteTile = {
    item: {
      image: "https://boxt-bcms.imgix.net/lS53JZBxQyOLXnYD2pTw",
      meta: { id: generatedTiles.length + 1 },
      onClick: () => {
        setIsFinishQuoteBottomSheetOpen(false);
        setIsNewQuoteBottomSheetOpen(true);
      },
      slug: "start-new-quote",
      title: t("title-hero.new-quote"),
    },
  };

  const finishQuoteTiles = [...generatedTiles, newQuoteTile];

  return {
    finishQuoteTiles,
    handleFinishQuoteClick,
    hasExistingScreeners,
    isFinishQuoteBottomSheetOpen,
    isFinishQuoteLoading,
    isLoading,
    isNewQuoteBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
    setIsNewQuoteBottomSheetOpen,
  };
};

export default useQuotes;
