import type { RefObject } from "react";
import type { EventArgs } from "react-ga";
import { Grid, Spacing } from "@boxt/design-system";

import { actions, category, labels } from "@Lib/googleAnalytics";
import NoPaddingGrid from "@Components/layout/NoPaddingGrid";
import ValuePropBanner from "@Components/pages/boilers/subscription/ValuePropBanner";
import { LandingSliderWithCTA, TrustpilotReviewsWidget, WaysToPay } from "@Components/pages/common";
import AchievementTilesComponent from "@Components/pages/common/AchievementTilesComponent";
import BottomPostcodeWithCTA from "@Components/pages/common/BottomPostcodeCTA/BottomPostcodeCTA";
import Butter3Column from "@Components/pages/common/Butter3Column";
import Butter5050Hero from "@Components/pages/common/Butter5050Hero";
import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";
import ButterLandingFAQ from "@Components/pages/common/ButterLandingFAQ";
import { ModuleContainer } from "@Components/pages/common/ButterLandingFAQ/styles";
import ButterLandingHero from "@Components/pages/common/ButterLandingHero";
import ButterListCard from "@Components/pages/common/ButterListCard";
import ButterSpacing from "@Components/pages/common/ButterSpacing";
import ButterTitleHero from "@Components/pages/common/ButterTitleHero";
import { ButterComparisonTable } from "@Components/pages/common/ComparisonTable/ButterComparisonTable";
import EngineerMap from "@Components/pages/common/EngineerMap";
import BulletList from "@Components/pages/common/EngineerMap/components/BulletList";
import FullWidthImage from "@Components/pages/common/FullWidthImage";
import GridCard from "@Components/pages/common/GridCard";
import { CardContainer } from "@Components/pages/common/GridCard/styles";
import GuideCTA from "@Components/pages/common/GuideCTA";
import GuideSidePanelCTA from "@Components/pages/common/GuideSidePanelCTA";
import HowBoxtWorks from "@Components/pages/common/HowBoxtWorks";
import ImageText from "@Components/pages/common/ImageText";
import InstallationsGuideSlider from "@Components/pages/common/InstallationsGuideSlider";
import Landing5050 from "@Components/pages/common/Landing5050";
import { Wrapper } from "@Components/pages/common/Landing5050/styles";
import LandingAsEasyAs from "@Components/pages/common/LandingAsEasyAs";
import LandingImageCollection from "@Components/pages/common/LandingImageCollection";
import LandingImageSlider from "@Components/pages/common/LandingImageSlider";
import LandingImageTiles from "@Components/pages/common/LandingImageTiles";
import LandingPartnersSlider from "@Components/pages/common/LandingPartnersSlider";
import LandingProductTiles from "@Components/pages/common/LandingProductTiles/LandingProductTiles";
import LandingRedirect from "@Components/pages/common/LandingRedirect";
import LandingSingleVideo from "@Components/pages/common/LandingSingleVideo";
import LandingTrustpilot from "@Components/pages/common/LandingTrustpilot";
import LandingWhySection from "@Components/pages/common/LandingWhySection";
import LinkList from "@Components/pages/common/LinkList";
import NavigationBlock from "@Components/pages/common/NavigationBlock";
import NavigationPills from "@Components/pages/common/NavigationPills";
import Testimonials from "@Components/pages/common/Testimonials";
import { ContentWrapper } from "@Components/pages/content/styles";
import type { ButterComponent } from "@Components/pages/content/types";
import FinanceTabs from "@Components/pages/finance/Tabs";
import { FinanceTabsWrapper } from "@Components/pages/list/styles";

import { generateLgGridRule } from "./generateLgGridRule";
import { generateMdGridRule } from "./generateMdGridRule";

const gaSliderWithCTAScrollEvent: EventArgs = {
  action: actions.readyToGetStarted,
  category: category.listLanding,
  label: labels.sliderWithCTA,
};

const gaInSafeHandsScrollEvent: EventArgs = {
  action: actions.readyToGetStarted,
  category: category.subscriptionsLanding,
  label: labels.inSafeHands,
};

const boilerSlug = "boiler-subscription";

export const getHeroForPage = (
  modules: ButterComponent[] = [],
  slug?: string,
  scrollTargetRef?: RefObject<HTMLDivElement | null>,
) =>
  modules?.map(({ fields, type }: ButterComponent, index) => {
    switch (type) {
      case "50_50_hero":
        return <Butter5050Hero fields={fields} key={`${slug}_5050_hero-${index}`} />;
      case "landing_hero":
        return (
          <ButterLandingHero
            fields={fields}
            hasArrowDown={
              slug
                ? ["solar", "boilers-ab", "heat-pumps", "home-battery-storage", "hybrid-heat-pumps"].includes(slug)
                : false
            }
            key={`${slug}_landing_hero-${index}`}
            scrollTargetRef={scrollTargetRef}
          />
        );
      default:
        return null;
    }
  });

export const getButterComponentsForPage = (
  modules: ButterComponent[] = [],
  slug?: string,
  scrollTargetRef?: RefObject<HTMLDivElement> | null,
) =>
  modules.map(({ fields, type }: ButterComponent, index) => {
    switch (type) {
      case "finance_tabs_configurable":
        return (
          <FinanceTabsWrapper key={`finance_tabs-${index}`}>
            <FinanceTabs content={fields} />
          </FinanceTabsWrapper>
        );
      case "content_component":
        return (
          <ContentWrapper key={`${slug}_content_component-${index}`}>
            <ButterHTMLParsingContent fields={fields} idAttribute={`${slug}_content-component-${index}`} />
          </ContentWrapper>
        );
      case "image_collection":
        return (
          <LandingImageCollection
            fields={fields}
            idAttribute={`image_collection-${index}`}
            key={`${slug}_image_collection-${index}`}
          />
        );
      case "image_gallery":
        return (
          <Spacing key={`image_gallery_component-${index}`} mv={5}>
            <ContentWrapper>
              <LandingImageSlider fields={fields} keyName={`image_gallery_component-${index}`} />
            </ContentWrapper>
          </Spacing>
        );
      case "image_text_with_cta":
        return (
          <Spacing key={`image_text_with_cta-${index}`} mv={5}>
            <ContentWrapper>
              <ImageText fields={fields} />
            </ContentWrapper>
          </Spacing>
        );
      case "trustpilot":
        return (
          <LandingTrustpilot
            idAttribute={`${slug}_trustpilot-${index}`}
            key={`${slug}_trustpilot-${index}`}
            widget={fields.widget}
          />
        );
      case "how_boxt_works_stepper":
        return <HowBoxtWorks fields={fields} key={`${slug}_how-boxt-works-stepper-${index}`} />;
      case "product_tiles":
        return (
          <Spacing key={`${slug}_product-tiles-${index}`} mv={6}>
            <LandingProductTiles fields={fields} idAttribute={`${slug}_product-tiles-${index}`} isBottomSheet={false} />
          </Spacing>
        );
      case "slider_with_cta":
        return (
          <LandingSliderWithCTA
            content={fields}
            gaScrollEvent={slug === boilerSlug ? gaInSafeHandsScrollEvent : gaSliderWithCTAScrollEvent}
            i18nNamespace="common"
            key={`${slug}_slider_with_cta-${index}`}
            keyName="slider_with_cta"
          />
        );
      case "guide_cta":
        return (
          <ContentWrapper key={`${slug}_guide-cta-${index}`}>
            <GuideCTA fields={fields} />
          </ContentWrapper>
        );
      case "achievement_tiles":
        return (
          <AchievementTilesComponent
            fields={fields}
            idAttribute={`${slug}_achievement-tiles-${index}`}
            key={`${slug}_achievement-tiles-${index}`}
          />
        );
      case "title":
        return (
          <ButterTitleHero fields={fields} idAttribute={`${slug}_title-${index}`} key={`${slug}_title-${index}`} />
        );
      case "full_width_image":
        return (
          <Spacing key={`${slug}_full_width_image_component-${index}`} mt={{ lg: 8, sm: 6 }}>
            <FullWidthImage
              fields={fields}
              height={968}
              idAttribute={`full_width_image_component-${index}`}
              isZoomable
              width={1585}
            />
          </Spacing>
        );
      case "testimonials":
        return (
          <Testimonials
            fields={fields}
            idAttribute={`${slug}_testimonials-${index}`}
            key={`${slug}_testimonials-${index}`}
          />
        );
      case "as_easy":
        if (["boiler-repair", "google-repair", "home-cover-hero", boilerSlug].includes(slug as string)) {
          return <LandingAsEasyAs content={fields} key={`as_easy-${index}`} />;
        } else {
          return (
            <NoPaddingGrid>
              <Grid.Row key={`${slug}_as-easy-as-${index}`}>
                <Grid.Col>
                  <Spacing mb={5}>
                    <LandingAsEasyAs content={fields} hasBackgroundColor={false} isFullWidth={false} />
                  </Spacing>
                </Grid.Col>
              </Grid.Row>
            </NoPaddingGrid>
          );
        }
      case "engineer_map":
        return <EngineerMap fields={fields} />;
      case "bullet_list":
        return <BulletList fields={fields} />;
      case "list_card":
        return <ButterListCard fields={fields} />;
      case "faq":
        if (slug === boilerSlug) {
          return (
            <ModuleContainer key={`faq-${index}`}>
              <ButterLandingFAQ
                content={fields}
                gaCategory={category.subscriptionsLanding}
                header={fields.header}
                keyName={`${slug}_faq-${index}`}
              />
            </ModuleContainer>
          );
        } else {
          return (
            <ButterLandingFAQ
              content={fields}
              gaCategory={category.financeLanding}
              header={fields.header}
              key={`${slug}_faq-${index}`}
              keyName={`faq-${index}`}
              tabsWrapperProps={{ lg: { offset: 3, span: 10 } }}
            />
          );
        }
      case "finance_faq":
        return (
          <Spacing key={`finance_faq-${index}`} mv={5}>
            <ButterLandingFAQ
              content={fields}
              gaCategory={category.listLanding}
              header={fields.header}
              keyName="finance_faq"
            />
          </Spacing>
        );
      case "landing_50_50":
        return <Landing5050 fields={fields} key={`${slug}_generic-50-50-${index}`} />;
      case "navigation_pills":
        return <NavigationPills fields={fields} key={`navigation-pills-${index}`} />;
      case "three_column":
        return (
          <Spacing key={`${slug}_three_column-${index}`} mv={5}>
            <Butter3Column
              fields={fields}
              idAttribute={`three-colomn-${index}`}
              keyName={`three_column_component-${index}`}
            />
          </Spacing>
        );
      case "navigation_block_tiles":
        return (
          <Spacing key={`${slug}_navigation-block-${index}`} mv={5}>
            <NavigationBlock fields={fields} idAttribute={`navigation-block-${index}`} />
          </Spacing>
        );
      case "ways_to_pay":
        return <WaysToPay fields={fields} key={`${slug}_ways-to-pay-${index}`} />;
      case "trustpilot_widget":
        return <TrustpilotReviewsWidget fields={fields} key={`${slug}_trustpilot-widget-${index}`} />;
      case "redirect":
        return <LandingRedirect fields={fields} key={`${slug}_redirect-${index}`} />;
      case "partner_component":
        return <LandingPartnersSlider fields={fields} key={`${slug}_partners-${index}`} keyName="partners" />;
      case "spacing_component":
        return <ButterSpacing fields={fields} key={`${slug}_spacing-${index}`} />;
      case "image_tiles":
        return <LandingImageTiles content={fields} key={`${slug}_image-titles-${index}`} />;
      case "bottom_postcode_cta":
        return (
          <BottomPostcodeWithCTA content={fields} i18nNamespace="common" key={`${slug}_bottom-postcode-${index}`} />
        );
      case "single_video":
        return <LandingSingleVideo content={fields} key={`single_video-${index}`} />;
      case "installation_guide_slider":
        return <InstallationsGuideSlider fields={fields} key={`installation_guide_slider-${index}`} />;
      case "guide_faq":
        return (
          <Spacing key={`guides_faq_component-${index}`} mv={5}>
            <ButterLandingFAQ
              content={fields}
              gaCategory={category.listGuideFAQ}
              header={fields.header}
              keyName="faq"
            />
          </Spacing>
        );
      case "guide_sidepanel_cta":
        return (
          <Spacing key={`sidepanel_cta-${index}`} mv={5}>
            <ContentWrapper>
              <GuideSidePanelCTA fields={fields.sidepanel_cta} />
            </ContentWrapper>
          </Spacing>
        );
      case "why_section":
        return (
          <LandingWhySection content={fields} gaScrollEvent={gaSliderWithCTAScrollEvent} key={`why_section-${index}`} />
        );
      case "link_list":
        return <LinkList fields={fields} />;
      case "dynamic_card_grid":
        return (
          <CardContainer
            $desktopGridLayout={generateLgGridRule(fields.cards)}
            $tabletGridLayout={generateMdGridRule(fields.cards)}
            key={`${slug}_dynamic-card-grid-${index}`}
            ref={scrollTargetRef}
          >
            {fields.cards.map((card, cardIndex) => (
              <GridCard card={card} key={`card-grid-${cardIndex}`} />
            ))}
          </CardContainer>
        );
      case "value_proposition_banner":
        return (
          <>
            <Wrapper $bgColor={fields?.background_colour?.name}>
              <Spacing mh={{ lg: 8, md: 0, sm: 0 }} mv={5}>
                <Grid>
                  <Grid.Row>
                    <Grid.Col>
                      <ValuePropBanner fields={fields} />
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Spacing>
            </Wrapper>
          </>
        );
      case "comparison_table":
        return <ButterComparisonTable fields={fields} i18nNamespace="common" />;
      default:
        // Not yet supported ButterCMS component type
        return null;
    }
  });
