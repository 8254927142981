import { Fragment } from "react";
import Link from "next/link";
import { Grid, H3, H5, Spacing } from "@boxt/design-system";

import { DottedLine } from "@Components/DiscountsDisplay/styles";

import { ArrowSvg, LinkLine, StyledButton } from "./styles";
import type { ButterLinkListFields } from "./types";

export type Props = {
  fields: ButterLinkListFields;
};

const LinkList = ({ fields }: Props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={{ offset: 2, span: 12 }}>
          <Spacing mv={3}>
            <Spacing mb={4}>
              <H3 align="center">{fields.title}</H3>
            </Spacing>
            {fields.bullets.map(({ title, url }, index) => (
              <Fragment key={index}>
                <LinkLine data-testid={`link-${index}`}>
                  <H5>{title}</H5>
                  <Link href={url} legacyBehavior passHref target="_blank">
                    <StyledButton>
                      <ArrowSvg />
                    </StyledButton>
                  </Link>
                </LinkLine>
                {index < fields.bullets.length - 1 ? <DottedLine /> : null}
              </Fragment>
            ))}
          </Spacing>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default LinkList;
