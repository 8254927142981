import styled from "styled-components";
import { rem } from "@boxt/design-system";

import { ReactComponent as ArrowSVG } from "@Images/icons/circled-arrow.svg";

export const LinkLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)};
`;

export const ArrowSvg = styled(ArrowSVG)`
  width: ${rem(32)};
  height: ${rem(32)};
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
